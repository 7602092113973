import { login, getUserInfo } from '@/api/sys'
import { setItem, getItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
// import router from '@/router'

export default {
    namespaced: true,
    state: () => ({
        // 定义token属性
        token: getItem(TOKEN) || '',
        userInfo: {},
    }),
    mutations: {
        // Token设置的方法
        setToken (state, token) {
            state.token = token
            setItem(TOKEN, token)
        },
        setUserInfo (state, userInfo) {
            state.userInfo = userInfo
        },
    },
    actions: {
        login (context, loginParam) {
            return new Promise((resolve, reject) => {
                login(loginParam).then(data => {
                    // 触发token方法
                    // this.commit('user/setToken', data.token)
                    // 跳转
                    // router.push('/')
                    // window.console.log(data);
                    resolve(data)
                }).catch(err => {
                    window.console.log(err);
                    reject(err)
                })
            })
        },
        async getUserInfo () {
            const res = await getUserInfo()
            this.commit('user/setUserInfo', res)
            return res
        }
    }
}
