import router from '@/router'
import store from '@/store'

// 白名单
const whiteList = ['/login']

/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
    // 存在tonken, 进入主页
    if (store.getters.token) {
        // 1.用户已登录，则不允许进入 login
        if (to.path === '/login') {
            next('/')
        } else {
            // 判断用户资料是否获取
            // 若不存在用户信息，则需要获取用户信息
            // console.log('store.getters.hasUserInfo', store.getters.hasUserInfo);
            if (!store.getters.hasUserInfo) {
                // 触发获取用户信息的 action
                await store.dispatch('user/getUserInfo')
            }
            next()
        }
    } else {
        // 没有token的情况下，可以进入白名单
        if (whiteList.indexOf(to.path) > -1) {
            next()
        } else {
            next('/login')
        }
    }
})
