import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'

// console.log('process.env.VUE_APP_BASE_API', process.env)
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在这个位置需要统一的去注入token
        if (store.getters.token) {
            // 如果token存在 注入token
            config.headers.Authorization = `Bearer ${store.getters.token}`
        }
        return config // 必须返回配置
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    // 请求成功
    response => {
        const { success, message } = response.data;
        // 需要判断当前请求是否成功
        if (success) {
            // 成功返回解析后的数据
            return response.data
        } else {
            // 失败（请求成功、业务失败）, 消息提示
            ElMessage.error(message) // 提示错误消息
            return Promise.reject(new Error(message))
        }
    },
    // 请求失败
    error => {
        ElMessage.error(error.message) // 提示错误消息
        return Promise.reject(new Error(error.message))
    }
)

export default service
