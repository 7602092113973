import { createStore } from 'vuex'
import user from './module/use'
import getters from './getters'

export default createStore({
    getters,
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        user
    }
})
